export class Navigator{
    async footer(){
        const footerNav = document.querySelector('#SiteFooterNav')
    
        try {
            const response = await fetch('/metros/api/footer', { method: 'GET' });
            const data = await response.json();
            
            for (let i = 0; i < data.length; i++) {
                const section = data[i].top;
                
                for (let  name in section) {
                    const divOuter = document.createElement('div')
                    const divInner = document.createElement('div')
                    const h4 = document.createElement('h4')
                    const ul = document.createElement('ul')
                    
                    let nav = section[name]
                    h4.innerText = Object.keys(section[name])[0]
                    h4.classList.add('col-md-10','text-start','border-bottom','pb-2','mx-2','text-light')
                    ul.classList.add('col-md-10','list-unstyled','text-start')
                    
                    divOuter.classList.add(
                        `${i%2==0 && i !=2 ? 'col-lg-3' : 'col-lg-2'}`,
                        'col-md-6','d-flex','justify-content-center','mb-4'
                        )
                    divInner.classList.add('row')
                    divInner.append(h4)    
                        for( let title in nav){
                            for(let i=0;i<=nav[title].nav.length;i++){
                                let obj = nav[title].nav[i]
                                
                                for (let key in obj) {
                                    const li = document.createElement('li')
                                    const anchor = document.createElement('a')

                                    // li.classList.add('d-flex')  
                                    anchor.classList.add('d-flex','mx-1','icon-link','justify-content-start','align-items-center','mb-1','p-2','text-nowrap')  
                                    
                                    if (Object.hasOwnProperty.call(obj, key)) {
                                        let item = obj[key];
                                        
                                        anchor.innerHTML = item.icon + item.label
                                        // anchor.href=item.link.replace(/[\s\.]/g,'-')
                                        anchor.href=item.link
                                        anchor.target='_blank'
                                        anchor.rel='noopener noreferrer'
                                        anchor.title=item.alt
                                        anchor.alt=item.alt

                                        if (item.onclick) {
                                            anchor.setAttribute('onclick', item.onclick['onclick']);
                                        }
                                        li.append(anchor) 
                                    }
                                    ul.append(li)
                                }
                                    
                                divInner.append(ul) 
                            }
                            divOuter.append(divInner)
                        }
                        footerNav.append(divOuter)
                    i++
                }
            }

        } catch (error) {
            console.error('Error fetching footer data: ', error);
        }
    }   
}